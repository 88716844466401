import { motion } from 'framer-motion'
import { slideInFromLeft, slideInFromRight } from '../../utils/motion'
import HeroImg from '../../assets/img/hero-img.png'

function Header(){

    return(
      <main className='bg-hero bg-cover bg-center bg-no-repeat'>
        <div className='container mx-auto max-w-7xl'>
          <div className="px-6 lg:px-8">
            <div className="mx-auto max-w-full xl:mx-12">
              <div className='flex flex-col lg:flex-row items-center'>
                <div className='width-3/5 flex flex-col items-center lg:items-start order-2 lg:order-1'>
                  <motion.h1
                    className="text-xl font-semibold text-gray-800 tracking-tight pt-4 sm:text-5xl"
                    initial={"offScreen"}
                    whileInView={"onScreen"}
                    viewport={{once: true}}
                    variants={slideInFromLeft(0.4)}
                  >
                    Corpoindustrial
                  </motion.h1>
                  <motion.p
                    className="mt-6 text-2xl max-w-3xl leading-8 text-gray-700"
                    initial={"offScreen"}
                    whileInView={"onScreen"}
                    viewport={{once: true}}
                    variants={slideInFromLeft(0.6)}
                  >
                    Somos la Corporación que agremia a los industriales y empresarios de Norte de Santander en sinergia para el crecimiento, desarrollo y materialización de iniciativas de beneficio común.
                  </motion.p>
                </div>
                <motion.div
                  className="w-2/5 order-1 lg:order-2"
                  initial={"offScreen"}
                  whileInView={"onScreen"}
                  viewport={{once: true}}
                  variants={slideInFromRight(0.8)}
                >
                  <img src={HeroImg} className='w-full object-contain'/>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
    </main>
    )
}

export default Header