import React from 'react'

function SocialButton() {
  return (
    <div className='relative overflow-hidden group z-20'>
      <div className='fixed -right-36 group-hover:right-12 bottom-24 pl-3 pr-4 py-1 transition-all duration-100 ease-linear bg-yellow-corpo rounded-l-full'>
        <ul className='flex items-center'>
          <li className='mx-2 tooltip tooltip-left' data-tip="Facebook">
            <a href='https://www.facebook.com/people/Corpoindustrial-C%C3%BAcuta/61550536693300/' rel='nofollow' target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
            </a>
          </li>
          <li className='mx-2 tooltip tooltip-left' data-tip="Instagram">
            <a href='https://www.instagram.com/corpoindustrialnorte/' rel='nofollow' target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
            </a>
          </li>
          <li className='mx-2 tooltip tooltip-left' data-tip="X">
            <a href='https://twitter.com/Corpoinduscuc' rel='nofollow' target='_blank'>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-x" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
            </a>
          </li>
        </ul>
      </div>
      <div className='fixed flex items-center px-3 py-1 right-0 bottom-24 bg-yellow-corpo rounded-l-full'>
        <div className='cursor-pointer mx-2 mb-0.5'>
          <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" strokeLinecap="round" strokeWidth="8" fill="none" stroke="#fff" width="30" height="30" viewBox="0 0 147.03 150.17"><path className="cls-1" d="m74.18,4.38c39.07,0,70.85,31.78,70.85,70.84s-31.78,70.84-70.85,70.84c-11.97,0-23.81-3.05-34.24-8.81l-.71-.39-.78.21-34.27,9.35,9.54-33.04.24-.84-.45-.75c-6.65-11.01-10.17-23.66-10.17-36.58C3.34,36.16,35.12,4.38,74.18,4.38m0-2C33.96,2.38,1.34,34.99,1.34,75.22c0,13.77,3.82,26.63,10.46,37.61L1.26,149.29l37.71-10.29c10.44,5.77,22.44,9.06,35.21,9.06,40.24,0,72.85-32.62,72.85-72.84S114.42,2.38,74.18,2.38h0Z"/><path className="cls-2" d="m88.13,56.43c0,9-6.49,19.45-14.49,19.45s-14.49-10.45-14.49-19.45,6.49-14.22,14.49-14.22,14.49,5.22,14.49,14.22Z"/><path className="cls-1" d="m49.02,80.09h49.26c1.92,0,3.48,1.56,3.48,3.48v23.88h-56.22v-23.88c0-1.92,1.56-3.48,3.48-3.48Z"/></svg>
        </div>
      </div>
    </div>
  )
}

export default SocialButton