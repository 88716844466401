import {connect} from 'react-redux'
import logo from "assets/img/logo-full.png";

const navigation = {
    solutions: [
      { name: 'Inicio', href: '/' },
      { name: 'Blog', href: '/blog' },
      { name: 'Bolsa de Empleo', href: '/empleos' },
    ],
    support: [
        { 
          icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" /><path d="M3 7l9 6l9 -6" /></svg>,
          name: 'info@corpoindustrial.co'
        },
        { 
          icon: <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-whatsapp" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" /><path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" /></svg>,
          name: '322-3073-276'
        },
    ],
    company: [
        { name: 'Casos', href: '/casos' },
        { name: 'Servicios', href: '/servicios' },
        { name: 'Nosotros', href: '/nosotros' },
        { name: 'Carreras', href: '/carreras' },
    ],
    legal: [
      { name: 'Privacidad', href: '#' },
      { name: 'Términos', href: '#' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/people/Corpoindustrial-C%C3%BAcuta/61550536693300/',
        icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-facebook" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>
        ),
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/corpoindustrialnorte/',
        icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-instagram" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" /><path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" /><path d="M16.5 7.5l0 .01" /></svg>
        ),
      },
      {
        name: 'X',
        href: 'https://twitter.com/Corpoinduscuc',
        icon: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4l11.733 16h4.267l-11.733 -16z" /><path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" /></svg>
        ),
      },
    ],
  }

function Footer(){
    return(
      <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className='container mx-auto max-w-7xl'>
          <div className="mx-auto max-w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-4 xl:col-span-1">
                <img
                  src={logo}
                  width={220}
                  height={220}
                  className=""
                />
                <div className="flex space-x-6">
                  {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500" rel='nofollow' target='_blank'>
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
              <div className="mt-12 grid grid-cols-1 md:grid-cols-2 md:gap-8 xl:col-span-2 xl:mt-0">
                <div className="grid grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-base font-medium text-gray-900">Visítanos</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {navigation.solutions.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">Social</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {navigation.social.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900 flex items-center gap-2">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-1 md:gap-8">
                  <div className="mt-12 md:mt-0">
                    <h3 className="text-base font-medium text-gray-900">Contáctanos</h3>
                    <ul role="list" className="mt-4 space-y-4">
                      {navigation.support.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-base text-gray-500 hover:text-gray-900 flex items-center gap-2">
                            {item.icon}
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-200 pt-8">
              <p className="text-base text-gray-400 xl:text-center">&copy; 2024 Corpoindustrial. Todos los derechos reservados.</p>
            </div>
          </div>
        </div>
      </footer>
    )
}

const mapStateToProps=state=>({

})

export default connect(mapStateToProps, {

}) (Footer)