import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import store from "./store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import AnimatedRoutes from "Routes";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Corpoindustrial | Inicio</title>
        <meta
          name="description"
          content="Corporación que agremia a los empresarios de Norte de Santander."
        />
        <meta
          name="keywords"
          content="empresas, agremiados, norte de santander"
        />
        <meta name="robots" content="all" />
        <link rel="canonical" href="https://corpoindustrial.co/" />
        <meta name="author" content="Corpoindustrial" />
        <meta name="publisher" content="Corpoindustrial" />

        {/* Social Media Tags */}
        <meta property="og:title" content="Corpoindustrial" />
        <meta
          property="og:description"
          content="Corporación que agremia a los empresarios de Norte de Santander."
        />
        <meta property="og:url" content="https://corpoindustrial.co/" />
        <meta
          property="og:image"
          content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
        />

        <meta name="twitter:title" content="Corpoindustrial" />
        <meta
          name="twitter:description"
          content="Corporación que agremia a los empresarios de Norte de Santander."
        />
        <meta
          name="twitter:image"
          content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>
          <AnimatedRoutes />
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
