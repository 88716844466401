import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/es";
function BlogCardHorizontal({ data, index }) {
	return (
		<article className="relative group border-y border-r border-neutral-100 p-3 rounded-lg border-l-8 border-l-green-corpo bg-white">
			<div className="">
				<div className="mb-2">
					<div className="sr-only">Fecha</div>
					<div className="whitespace-nowrap text-sm leading-6 text-slate-500 dark:text-slate-400 flex items-center lg:block gap-2">
						<div className="capitalize order-2 lg:order-1">
							{moment(data.published).format("MMM D, YYYY")}
						</div>
					</div>
				</div>
				<h2 className="text-base font-semibold tracking-tight text-blue-button text-slate-600 pt-8 lg:pt-0">
					{data.title}
				</h2>
				<div className="my-2 prose prose-slate prose-a:relative prose-a:z-10 dark:prose-dark">
					<p className="line-clamp-3">{data.description}</p>
				</div>
			</div>
			<div className="flex gap-2 my-2">
				{data.categories.map((category, index) => (
					<div key={index}>
						<Link
							to={`/category/${category.slug}`}
							className="rounded-md bg-yellow-button/30 text-xs px-2 bg-yellow-corpo/30 py-1.5 font-medium text-slate-500 hover:bg-red-button/60"
						>
							{category.name}
						</Link>
					</div>
				))}
			</div>
			<div className="mb-1">
				<span className="text-slate-500 text-sm">
					{data.time_read} min de lectura
				</span>
			</div>

			<Link
				className="flex items-center text-sm text-blue-button font-medium"
				to={`/blog/${data.slug}`}
			>
				<span className="-inset-y-2.5 -inset-x-4 md:-inset-y-4 md:-inset-x-6 sm:rounded-2xl"></span>
				<span className="relative">Leer artículo</span>
				<svg
					className="relative mt-px overflow-visible ml-2.5 text-blue-button dark:text-sky-700"
					width="3"
					height="6"
					viewBox="0 0 3 6"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M0 0L3 3L0 6"></path>
				</svg>
			</Link>
		</article>
	);
}
export default BlogCardHorizontal;
