import React from 'react'
import { Link } from 'react-router-dom'
import AwardsBG from '../../assets/img/banner-awards-1.png'
import AwardsImg from '../../assets/img/texto-awards.png'
import AwardsImg2 from '../../assets/img/texto-awards-m.png'
import CategoriesDocument from '../../assets/pdf/categorias-premios-corpoindustrial-2024.pdf'

const Awards = () => {
  return (
    <section className='w-full h-auto overflow-hidden relative z-0 p-4 lg:p-12'>
      <div className='absolute inset-0 -z-10'>
        <img src={AwardsBG} className='h-full w-full' alt='Awards Background' />
      </div>
      <div className='container mx-auto max-w-7xl bg-[#141414] z-10 lg:p-0 flex justify-center items-center'>
        <div className='flex flex-col items-center justify-center p-8 lg:py-20'>
          <img src={AwardsImg} className='w-full hidden md:block' alt='Awards' />
          <img src={AwardsImg2} className='w-full mb-4 block md:hidden' alt='Awards' />
          <Link to={'/premios-corpoindustrial'} target='_blank' className='bg-yellow-corpo rounded-lg px-2 py-2 text-gray-800 font-bold text-center text-sm lg:text-lg w-full lg:w-auto mt-4 mb-3 hover:scale-110 transition-all duration-150 ease-linear flex items-center justify-center gap-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-edit h-6 w-6">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
              <path d="M16 5l3 3" />
            </svg>
            ¡Registra tu empresa ahora!
          </Link>
          <Link to={CategoriesDocument} target='_blank' className='border border-yellow-corpo rounded-lg px-2 py-2 text-yellow-corpo font-medium text-center text-sm lg:text-lg w-full lg:w-auto hover:scale-110 transition-all duration-150 ease-linear flex items-center justify-center gap-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle h-6 w-6">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
              <path d="M12 9h.01" />
              <path d="M11 12h1v4h1" />
            </svg>
            Conoce las categorías de este año
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Awards