import {
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAIL,
  GET_JOB_SUCCESS,
  GET_JOB_FAIL,
  GET_JOB_LIST_CATEGORIES_SUCCESS,
  GET_JOB_LIST_CATEGORIES_FAIL,
  GET_SEARCH_JOB_SUCCESS,
  GET_SEARCH_JOB_FAIL,
} from "../actions/job/types";

const initialState = {
  job_list: null,
  job_list_category: null,
  filtered_jobs: null,
  job: null,
  count: null,
  next: null,
  previous: null,
};

export default function job(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_JOB_LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        job_list_category: payload.results.jobs,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_JOB_LIST_CATEGORIES_FAIL:
      return {
        ...state,
        job_list_category: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_JOB_LIST_SUCCESS:
      return {
        ...state,
        job_list: payload.results.jobs,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_JOB_LIST_FAIL:
      return {
        ...state,
        job_list: null,
        count: null,
        next: null,
        previous: null,
      };
    case GET_JOB_SUCCESS:
      return {
        ...state,
        job: payload.job,
      };
    case GET_JOB_FAIL:
      return {
        ...state,
        job: null,
      };
    case GET_SEARCH_JOB_SUCCESS:
      return {
        ...state,
        filtered_jobs: payload.results.filtered_jobs,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
      };
    case GET_SEARCH_JOB_FAIL:
      return {
        ...state,
        filtered_jobs: null,
        count: null,
        next: null,
        previous: null,
      };
    default:
      return state;
  }
}
