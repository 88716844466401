import React from 'react'
import Navbar from "components/navigation/Navbar";
import AwardsForm from "components/forms/AwardsForm";
import { Helmet } from "react-helmet-async";
import Footer from 'components/navigation/Footer';

function CorpoindustrialAwards() {
  return (
    <div className={`bg-[#0A0A0A] bg-section-awards bg-cover bg-no-repeat min-h-screen`}>
      <Helmet>
				<title>Premios Corpoindustrial | Registro de Empresas</title>
				<meta
					name="description"
					content="Corporación que agremia a los empresarios de Norte de Santander."
				/>
				<meta
					name="keywords"
					content="empresas, agremiados, norte de santander"
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://corpoindustrial.co/" />
				<meta name="author" content="Corpoindustrial" />
				<meta name="publisher" content="Corpoindustrial" />

				{/* Social Media Tags */}
				<meta property="og:title" content="Corpoindustrial" />
				<meta
					property="og:description"
					content="Corporación que agremia a los empresarios de Norte de Santander."
				/>
				<meta property="og:url" content="https://corpoindustrial.co/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta name="twitter:title" content="Corpoindustrial" />
				<meta
					name="twitter:description"
					content="Corporación que agremia a los empresarios de Norte de Santander."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
      <header className="flex justify-center">
				<Navbar />
			</header>
      <div className='container max-w-3xl mx-auto pt-28 pb-16 px-4 lg:px-0'>
        <AwardsForm />
      </div>
			<Footer />
    </div>
  )
}

export default CorpoindustrialAwards