import ArveyDuqueImg from '../../assets/img/junta-directiva/arvey-duque.jpg'
import WilliamMondragonImg from '../../assets/img/junta-directiva/william-mondragon.png'
import HumbertoPosadaImg from '../../assets/img/junta-directiva/humberto-posada.jpg'
import GerardoAriasImg from '../../assets/img/junta-directiva/gerardo-arias.jpg'
import PedroMurilloImg from '../../assets/img/junta-directiva/pedro-murillo.jpg'
import OscarGerardinoImg from '../../assets/img/junta-directiva/oscar-gerardino.png'
import JoseTarcisioCelisImg from '../../assets/img/junta-directiva/jose-tarcisio-celis.jpg'
import JavierZapataImg from '../../assets/img/junta-directiva/javier-zapata.jpg'
import CarlosPradaImg from '../../assets/img/junta-directiva/carlos-prada.jpg'
import MildredVeraImg from '../../assets/img/junta-directiva/mildred-vera.jpg'
import AlexandraAlmanzaImg from '../../assets/img/junta-directiva/alexandra-almanza.jpg'
import BusinessmenModal from '../modals/BusinessmenModal'
import { motion } from 'framer-motion'
import { slideInFromLeft, slideInFromTop } from '../../utils/motion'

const businessmen = [
  { 
    id: 'arvey-duque-villamizar',
    name: 'Arvey Duque Villamizar',
    job: 'Presidente',
    photo: ArveyDuqueImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        EMPAQUETADORA DELNORTE SAS BIC.
      </div>
      <div>
        Es un empresa de Norte de Santander, Colombia, fundada en 2011. El objeto social es la comercialización de productos alimenticios y se da inicio al proyecto de adquisición de maquinaria industrial para el empaquetado de los mismo ( arroz azúcar y granos ), los avances y crecimiento de la comercializadora son el resultado de la tenacidad, compromiso visión disciplina y esfuerzo de su fundador y colaboradores es asi como en su proyecto de expansión estratégica en el año 2018 se inicia la comercialización de aceite de palma y soya y se establece la infraestructura industrial necesaria para el envasado de este producto. Los productos se comercializan al mayor a nivel nacional e internacional.
      </div>
    </>
  },
  { 
    id: 'william-mondragon-v',
    name: 'William Mondragón V.',
    job: 'Vicepresidente',
    photo: WilliamMondragonImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        CENS
      </div>
      <div>
        Empresa que busca generar valor a sus grupos de interés a través de la prestación del servicio de energía eléctrica de manera segura, eficiente, confiable y con respeto hacia el medio ambiente, contribuyendo así al mejoramiento de la calidad de vida de la población.
      </div>
    </>
  },
  { 
    id: 'humberto-posada',
    name: 'Humberto Posada',
    job: 'Veolia',
    photo: HumbertoPosadaImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        VEOLIA
      </div>
      <div>
        Empresa que ayuda a contribuir al progreso humano al comprometerse firmemente con los Objetivos de Desarrollo Sostenible establecidos por la ONU para lograr un futuro mejor y más sostenible para todos. Es con este objetivo en mente que Veolia se propone la tarea de "Renovar el mundo" a través de su negocio de servicios ambientales.
      </div>
    </>
  },
  { 
    id: 'gerardo-arias-u',
    name: 'Gerardo Arias U.',
    job: 'Lavandería Nuevo Milenio',
    photo: GerardoAriasImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        LAVANDERIA NUEVO MILENIO
      </div>
      <div>
        Emprendimiento cucuteño que lleva más de 20 años aportándole a la economía de la ciudad, construyendo de la mano de gente berraca, emprendedora se realiza la parte del proceso de lavado, en donde se transforma el denim, en tu prenda favorita.
      </div>
    </>
  },
  { 
    id: 'pedro-murillo-r',
    name: 'Pedro Murillo R.',
    job: 'Comecarnes',
    photo: PedroMurilloImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        COMECARNES
      </div>
      <div>
        La empresa se especializa en procesamiento y conservación de productos cárnicos. Se encuentra situada en el departamento norte de Santander, en la localidad cucuta con dirección avenida 6 # 6n-7n-130 zona industrial, cucuta procesadora de alimentos esta constituida como una sociedad por acciones simplificadas encargada de procesos de embutidos y carnes de primera calidad
      </div>
    </>
  },
  { 
    id: 'oscar-gerardino-a',
    name: 'Oscar Gerardino A.',
    job: 'Comfanorte',
    photo: OscarGerardinoImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        COMFANORTE
      </div>
      <div>
        Es una corporación de carácter privado, sin ánimo de lucro, que cumple funciones de seguridad social, con personalidad jurídica, otorgada por el Ministerio de Justicia mediante la resolución No 2894 de octubre 18 de 1957.
      </div>
    </>
  },
  { 
    id: 'jose-tarcisio-celis',
    name: 'José Tarcisio Celis',
    job: 'COGANOR',
    photo: JoseTarcisioCelisImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        COGANOR
      </div>
      <div>
        
      </div>
    </>
  },
  { 
    id: 'javier-zapata',
    name: 'Javier Zapata',
    job: 'Lavandería Stone Color',
    photo: JavierZapataImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        LAVANDERÍA STONE COLOR
      </div>
      <div>
        
      </div>
    </>
  },
  { 
    id: 'carlos-prada',
    name: 'Carlos Prada',
    job: 'Colombian Gymwear',
    photo: CarlosPradaImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        COLOMBIAN GYMWEAR
      </div>
      <div>
        
      </div>
    </>
  },
  { 
    id: 'mildred-vera',
    name: 'Mildred Vera',
    job: 'Casa Diesel',
    photo: MildredVeraImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        CASA DIESEL
      </div>
      <div>
        
      </div>
    </>
  },
  {
    id: 'alexandra-almanza',
    name: 'Alexandra Almanza',
    job: 'Directora Ejecutiva',
    photo: AlexandraAlmanzaImg,
    description: <>
      <div className='font-bold mt-2 mb-1'>
        
      </div>
      <div>
        
      </div>
    </>
  }
]

export default function Businessmen() {
  return (
    <div className="bg-white" id="junta-directiva">
      <div className='container mx-auto max-w-7xl'>
        <div className="mx-auto lg:mx-12 max-w-full py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
          <motion.div
            className='place-self-center'
            initial={"offScreen"}
            whileInView={"onScreen"}
            viewport={{once: true}}
            variants={slideInFromLeft(0.4)}
          >
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-green-corpo">Junta Directiva</h2>
            <p className="mt-4 text-lg text-gray-700">
              Conoce a los miembros que conforman la junta directiva de Corpoindustrial.
            </p>
          </motion.div>
          {/* <div className="mt-12 lg:col-span-2 lg:mt-0">
            <div className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-6 sm:gap-x-6 sm:gap-y-2 sm:space-y-0 lg:gap-x-6">
              {businessmen.map((businessman, index) => (
                <div key={index} className="relative">
                  <BusinessmenModal id={businessman.id} name={businessman.name} job={businessman.job} photo={businessman.photo} description={businessman.description} />
                </div>
              ))}
            </div>
          </div> */}
          <div className="mt-12 lg:col-span-2 lg:mt-0">
            <div className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-6 sm:gap-x-6 sm:gap-y-2 sm:space-y-0 lg:gap-x-6">
              {businessmen.map((businessman, index) => (
                <motion.div
                  className="border border-gray-100 rounded-full" key={index}
                  initial={"offScreen"}
                  whileInView={"onScreen"}
                  viewport={{once: true}}
                  variants={slideInFromTop(0.3 * index)}
                >
                  <div className='flex items-center'>
                    <div className='w-16 h-16 overflow-hidden rounded-full flex items-center justify-center'>
                      <img src={businessman.photo} className="h-16 w-16 object-cover text-green-corpo" aria-hidden="true" />
                    </div>
                    <div>
                      <span className="block ml-2 text-base font-bold text-gray-700">{businessman.name}</span>
                      <span className="block ml-2 text-sm text-gray-500">{businessman.job}</span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}