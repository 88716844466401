import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from "@heroicons/react/20/solid"
import SmallSetPaginationSearch from "components/pagination/SmallSetPaginationSearch"
import BlogCardSearch from "../BlogCardSearch"

function BlogList({posts,get_blog_list_page,count, term}){

    return(
      <div className="overflow-hidden px-8 bg-white">
        <h1 className="text-3xl font-bold text-center text-green-corpo">Resultados de la búsqueda</h1>
        <div className="mt-6 mb-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-5">
          {posts&&posts.map((post,index) => (
            <BlogCardSearch data={post} key={index} index={index}/>
          ))}
        </div>
        <SmallSetPaginationSearch list_page={get_blog_list_page} term={term} list={posts} count={count} />
      </div>
    )
}
export default BlogList