import React, { useEffect, useRef, useState } from 'react';
import GoalImg from '../../assets/img/goal-img.jpg';
import { motion } from 'framer-motion';
import { zoomIn } from '../../utils/motion';
import GearGreenImg from '../../assets/img/gear-green.svg';
import GearYellowImg from '../../assets/img/gear-yellow.svg';

export default function Goal() {

  return (
    <section className='relative overflow-hidden'>
      <img
        src={GearGreenImg}
        alt='Gear'
        className='gear-img w-96 h-96 hidden lg:block'
        id="gear-green"
      />
      <img
        src={GearYellowImg}
        alt='Gear'
        className='gear-img w-[450px] h-[450px] hidden lg:block'
        id="gear-yellow"
      />
      <div className="relative bg-white py-16 sm:py-24 container mx-auto max-w-7xl" id="goal">
        <div className="lg:mx-12 lg:grid lg:max-w-full lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
          <div className="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
              <motion.div
                className="relative overflow-hidden rounded-2xl py-40 shadow-xl"
                initial={"offScreen"}
                whileInView={"onScreen"}
                viewport={{once: true}}
                variants={zoomIn(0.4)}
              >
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={GoalImg}
                  alt="Junta Directiva de Corpoindustrial"
                />
              </motion.div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <motion.h2
                className="text-3xl text-green-corpo font-bold tracking-tight text-yellow-button sm:text-4xl"
                initial={"offScreen"}
                whileInView={"onScreen"}
                viewport={{once: true}}
                variants={zoomIn(0.6)}
              >
                Objetivo
              </motion.h2>
              <motion.div
                className="mt-6 space-y-6 text-gray-500"
                initial={"offScreen"}
                whileInView={"onScreen"}
                viewport={{once: true}}
                variants={zoomIn(0.8)}
              >
                <p className='mt-4 text-lg text-gray-700'>La Corporación de los Industriales y empresarios de Norte de Santander, CORPOINDUSTRIAL, tiene como objetivo principal:</p>
                <p className="mt-4 text-lg text-gray-700">
                  Procurar el <strong>desarrollo y crecimiento sostenible de la industria y la empresa</strong>, propendiendo por el mejoramiento económico, tecnológico, social y ambiental de toda la comunidad industrial y empresarial en articulación con los objetivos de desarrollo sostenible.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
