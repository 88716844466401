export default function CTA() {
    return (
      <div className="bg-gray-50">
        <div className="container mx-auto max-w-7xl">
          <div className="mx-auto lg:mx-12 max-w-full py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl">
              <span className="block mb-3">¿Quieres ser parte de Corpoindustrial?</span>
              <span className="block text-green-corpo text-2xl">No esperes más y comunícate con nosotros</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="https://api.whatsapp.com/send/?phone=573223073276&text&type=phone_number&app_absent=0"
                  target="_blank"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-corpo px-5 py-3 text-base font-medium text-white hover:bg-yellow-corpo transition duration-300 ease-in-out"
                >
                  Únete ahora
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }