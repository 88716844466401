import { Link } from "react-router-dom";
import Layout from "hocs/layouts/Layout";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";

function Error404() {
  return (
    <Layout>
      <header className="flex justify-center">
        <Navbar />
      </header>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-40 lg:px-8">
        <div className="text-center">
          <p className="text-5xl font-bold text-green-corpo">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Página no encontrada
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Lo sentimos, la página que estás buscando no existe
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-transparent border border-green-corpo text-green-corpo px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-green-corpo hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-corpo"
            >
              Volver al inicio
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </Layout>
  );
}
export default Error404;
