import AffiliatedCompaniesSlider from '../slider/AffiliatedCompaniesSlider'
import EmpanorteImg from '../../assets/img/logos/empanorte.png'
import LaMejorImg from '../../assets/img/logos/la-mejor.png'
import ColombianaSalsasImg from '../../assets/img/logos/colombiana-de-salsas.png'
import DistPastorJulioImg from '../../assets/img/logos/dist-pastor-julio.png'
import ProductosYermanImg from '../../assets/img/logos/productos-yerman.png'
import LavNuevoMilenioImg from '../../assets/img/logos/lav-nuevo-milenio.png'
import ColgasImg from '../../assets/img/logos/colgas.png'
import CoganorImg from '../../assets/img/logos/coganor.png'
import ArrozGelvezImg from '../../assets/img/logos/arroz-gelvez.png'
import TemporalImg from '../../assets/img/logos/temporal.png'
import IngDeAguasImg from '../../assets/img/logos/ing-de-aguas.png'
import CeramicaItaliaImg from '../../assets/img/logos/ceramica-italia.png'
import ComecarnesImg from '../../assets/img/logos/comecarnes.png'
import ComfanorteImg from '../../assets/img/logos/comfanorte.png'
import ComfaorienteImg from '../../assets/img/logos/comfaoriente.png'
import VeoliaImg from '../../assets/img/logos/veolia.png'
import CensImg from '../../assets/img/logos/cens.png'
import AmercianLittleImg from '../../assets/img/logos/american-little.png'
import AndecolImg from '../../assets/img/logos/andecol.png'
import WiediiImg from '../../assets/img/logos/wiedii.png'
import ServiRenaultImg from '../../assets/img/logos/servi-renault.png'
import CulmImg from '../../assets/img/logos/culm.png'
import SHWImg from '../../assets/img/logos/shw.png'
import VacunorteIpsImg from '../../assets/img/logos/vacunorte-ips.png'
import SurtilicoresImg from '../../assets/img/logos/surtilicores.png'
import BashaJeansImg from '../../assets/img/logos/basha-jeans.png'
import PartcarImg from '../../assets/img/logos/partcar.png'
import CorpoceroImg from '../../assets/img/logos/corpocero.png'
import LaAscensionImg from '../../assets/img/logos/la-ascension.png'
import CafeGalavisImg from '../../assets/img/logos/cafe-galavis.png'
import ClockImg from '../../assets/img/logos/clock.png'
import TerraSolarImg from '../../assets/img/logos/terra-solar.png'
import CucutaVipImg from '../../assets/img/logos/cucuta-vip.png'
import MarautosImg from '../../assets/img/logos/marautos.png'
import RtcImg from '../../assets/img/logos/rtc.png'
import JoyeriaDeFrancoImg from '../../assets/img/logos/joyeria-de-franco.png'
import IbesImg from '../../assets/img/logos/ibes.png'
import LaHieleraBlueImg from '../../assets/img/logos/la-hielera-blue.png'
import HadImg from '../../assets/img/logos/had.png'
import BlessImg from '../../assets/img/logos/bless.png'
import ThorImg from '../../assets/img/logos/thor.png'
import CasaDieselImg from '../../assets/img/logos/casa-diesel.png'
import SinanImg from '../../assets/img/logos/sinan.png'
import MadefrontImg from '../../assets/img/logos/madefront.png'
import DephenderBoxImg from '../../assets/img/logos/dephender-box.png'
import LavStoneColorImg from '../../assets/img/logos/lav-stone-color.png'
import Distrito4Img from '../../assets/img/logos/distrito-4.png'
import JhonPastelImg from '../../assets/img/logos/jhon-pastel.png'
import ArroceraNellyImg from '../../assets/img/logos/arrocera-nelly.png'
import DChicImg from '../../assets/img/logos/d-chic.png'
import CetaImg from '../../assets/img/logos/ceta.png'
import ClincaPenarandaImg from '../../assets/img/logos/clinica-oftalmologica-penaranda.png'
import ClubDeCazadoresImg from '../../assets/img/logos/club-de-cazadores.png'
import CeleusImg from '../../assets/img/logos/celeus.png'
import DelCorteAngaritaImg from '../../assets/img/logos/del-corte-angarita.png'
import DelBenImg from '../../assets/img/logos/del-ben.png'
import DistrifarCenterImg from '../../assets/img/logos/distrifar-center.png'
import ESSierraNevadaImg from '../../assets/img/logos/es-sierra-nevada.png'
import ESVenturaImg from '../../assets/img/logos/es-ventura.png'
import FusaroImg from '../../assets/img/logos/fusaro.png'
import EDSImg from '../../assets/img/logos/eds.png'
import GalautosImg from '../../assets/img/logos/galautos.png'
import GenerinImg from '../../assets/img/logos/generin.png'
import GrupoBerzaImg from '../../assets/img/logos/grupo-berza.png'
import MultiserviciosHFImg from '../../assets/img/logos/multiservicios-h-f.png'
import ProgresandoEnSaludImg from '../../assets/img/logos/progresando-en-salud.png'
import VegaltexImg from '../../assets/img/logos/vegaltex.png'
import IngeninImg from '../../assets/img/logos/ingenin.png'
import SE21Img from '../../assets/img/logos/se-21.png'
import MedcareImg from '../../assets/img/logos/medcare.png'
import CampoExpressImg from '../../assets/img/logos/campo-express.png'
import LaGranConsechaImg from '../../assets/img/logos/la-gran-cosecha.png'
import LavVictoriaImg from '../../assets/img/logos/lavanderia-victoria.png'
import LaGranEsquinaImg from '../../assets/img/logos/la-gran-esquina.png'
import LavNewWashImg from '../../assets/img/logos/lavanderia-new-wash.png'
import MaquinadosIndustrialesJHImg from '../../assets/img/logos/maquinados-industriales-jh.png'
import MBImg from '../../assets/img/logos/mb.png'
import MincoalImg from '../../assets/img/logos/mincoal.png'
import EpiccoImg from '../../assets/img/logos/epicco.png'
import OficanonImg from '../../assets/img/logos/oficanon.png'
import ProdottoImg from '../../assets/img/logos/prodotto.png'
import PTDImg from '../../assets/img/logos/ptd.png'
import DeLaCasaImg from '../../assets/img/logos/de-la-casa.png'
import SarayConstruccionesImg from '../../assets/img/logos/saray-construcciones.png'
import DoriImg from '../../assets/img/logos/dori.png'
import TNSImg from '../../assets/img/logos/tns.png'
import TransinverCTCImg from '../../assets/img/logos/transinver-ctc.png'
import TraperosElMejorImg from '../../assets/img/logos/traperos-el-mejor.png'
import TACImg from '../../assets/img/logos/tac.png'
import UniversoAgricolaImg from '../../assets/img/logos/universo-agricola.png'
import YLoShoesImg from '../../assets/img/logos/ylo-shoes.png'
import MCCormickImg from '../../assets/img/logos/mccormick.png'
import ESZonaRefrescanteImg from '../../assets/img/logos/es-zona-refrescante.png'
import VeinteImg from '../../assets/img/logos/veinte.png'
import ProinelectroImg from '../../assets/img/logos/proinelectro.png'
import VaoxImg from '../../assets/img/logos/vaox.png'
import CooparImg from '../../assets/img/logos/coopar.png'
import MetroPizzaImg from '../../assets/img/logos/metro-pizza.png'
import CMIImg from '../../assets/img/logos/cmi.png'
import CDAImg from '../../assets/img/logos/cda.png'
import ElPuntoTornilloImg from '../../assets/img/logos/el-punto-tornillo.png'
import MegawashImg from '../../assets/img/logos/megawash.png'
import VisitCucutaImg from '../../assets/img/logos/visit-cucuta.png'
import GymwearImg from '../../assets/img/logos/gymwear.png'
import ElPuntazoImg from '../../assets/img/logos/el-puntazo.png'
import Studio13Img from '../../assets/img/logos/studio-13.png'

const companies = [
  { id: 1, name: "Empanorte", img: EmpanorteImg, alt: "Empaquetadora del Norte", href: "https://empaquetadoradelnorte.com/" },
  { id: 2,  name: "Pasteurizadora La Mejor", img: LaMejorImg, alt: "Pasteurizadora La Mejor", href: "https://www.lamejor.com.co/index.html" },
  { id: 3, name: "Colombiana de Salsas", img: ColombianaSalsasImg, alt: "Colombiana de Salsas", href: "https://empresite.eleconomistaamerica.co/EMPRESA-COLOMBIANA-SALSAS-SAS.html" },
  { id: 4, name: "Distribuidora Pastor Julio Delgado", img: DistPastorJulioImg, alt: "Distribuidora Pastor Julio Delgado", href: "https://www.dpjd.com/" },
  { id: 5, name: "Productos Yerman", img: ProductosYermanImg, alt: "Productos Yerman", href: "#" },
  { id: 6, name: "Lavandería Nuevo Milenio", img: LavNuevoMilenioImg, alt: "Lavandería Nuevo Milenio", href: "https://www.facebook.com/profile.php?id=100067441185075&locale=es_LA" },
  { id: 7, name: "Colgas", img: ColgasImg, alt: "Colgas", href: "https://colgas.com/" },
  { id: 8, name: "Coganor", img: CoganorImg, alt: "Coganor", href: "https://coganor.com/" },
  { id: 9, name: "Arroz Gelvez", img: ArrozGelvezImg, alt: "Arroz Gelvez", href: "https://arrozgelvez.com/" },
  { id: 10, name: "Temporal", img: TemporalImg, alt: "Temporal", href: "https://www.temporalsa.com/" },
  { id: 11, name: "Ingeniería de Aguas", img: IngDeAguasImg, alt: "Ingeniería de Aguas", href: "https://www.ingeaguas.shop" },
  { id: 12, name: "Cerámica Italia", img: CeramicaItaliaImg, alt: "Cerámica Italia", href: "https://www.ceramicaitalia.com/" },
  { id: 13, name: "Comecarnes", img: ComecarnesImg, alt: "Comecarnes", href: "https://www.instagram.com/comecarnes_sas/?hl=es" },
  { id: 14, name: "Comfanorte", img: ComfanorteImg, alt: "Comfanorte", href: "https://comfanorte.com.co/index/" },
  { id: 15, name: "Comfaoriente", img: ComfaorienteImg, alt: "Comfaoriente", href: "https://comfaoriente.com/" },
  { id: 16, name: "Veolia", img: VeoliaImg, alt: "Veolia", href: "https://www.veolia.com/latamib/es" },
  { id: 17, name: "Cens", img: CensImg, alt: "Cens", href: "https://www.cens.com.co/" },
  { id: 18, name: "American Little", img: AmercianLittleImg, alt: "American Little", href: "https://www.americanlittle.com/" },
  { id: 19, name: "Andecol", img: AndecolImg, alt: "Andecol", href: "https://andecol.com.co/" },
  { id: 20, name: "Wiedii", img: WiediiImg, alt: "Wiedii", href: "https://wiedii.co/" },
  { id: 21, name: "Servi Renault", img: ServiRenaultImg, alt: "Servi Renault", href: "https://www.servirenault.com.co/" },
  { id: 22, name: "Clínica Urgencias La Merced", img: CulmImg, alt: "Clínica Urgencias La Merced", href: "https://culm.com.co/" },
  { id: 23, name: "SHW", img: SHWImg, alt: "SHW", href: "https://shw.com.co/" },
  { id: 24, name: "Vacunorte IPS", img: VacunorteIpsImg, alt: "Vacunorte IPS", href: "https://vacunorteips.com/" },
  { id: 25, name: "Surtilicores", img: SurtilicoresImg, alt: "Surtilicores", href: "https://surtilicores.com/" },
  { id: 26, name: "Mundo Basha Jeans", img: BashaJeansImg, alt: "Mundo Basha Jeans", href: "https://mundobashajeans.com/" },
  { id: 27, name: "Partcar", img: PartcarImg, alt: "Partcar", href: "https://partcar.com.co/" },
  { id: 28, name: "Corpocero", img: CorpoceroImg, alt: "Corpocero", href: "https://corpocero.com/" },
  { id: 29, name: "La Ascensión", img: LaAscensionImg, alt: "La Ascensión", href: "https://laascension.com/" },
  { id: 30, name: "Café Galavis", img: CafeGalavisImg, alt: "Café Galavis", href: "https://www.cafegalavis.com.co/" },
  { id: 31, name: "Clock", img: ClockImg, alt: "Clock", href: "https://clockfootwear.com/" },
  { id: 32, name: "Terra Solar", img: TerraSolarImg, alt: "Terra Solar", href: "https://www.terrasolar.com.co/" },
  { id: 33, name: "Cúcuta VIP", img: CucutaVipImg, alt: "Cúcuta VIP", href: "https://grupoinmobiliariovip.wixsite.com/misitio" },
  { id: 34, name: "Marautos", img: MarautosImg, alt: "Marautos", href: "https://www.marautos-parts.com/" },
  { id: 35, name: "RTC Empresa de Transporte", img: RtcImg, alt: "RTC Empresa de Transporte", href: "https://radiotaxirtc.com.co/" },
  { id: 36, name: "Joyería De Franco", img: JoyeriaDeFrancoImg, alt: "Joyería De Franco", href: "https://www.joyeriadefranco.com/" },
  { id: 37, name: "Ibes", img: IbesImg, alt: "Ibes", href: "https://ibes.edu.co/" },
  { id: 38, name: "La Hielera Blue", img: LaHieleraBlueImg, alt: "La Hielera Blue", href: "https://lahielerablue.com" },
  { id: 39, name: "HAD Seguros", img: HadImg, alt: "HAD Seguros", href: "https://hadseguros.com" },
  { id: 40, name: "Organización Bless", img: BlessImg, alt: "Organización Bless", href: "https://orgbless.com/" },
  { id: 41, name: "Seguridad Thor", img: ThorImg, alt: "Seguridad Thor", href: "https://www.seguridadthor.com/" },
  { id: 42, name: "Casa Diesel", img: CasaDieselImg, alt: "Casa Diesel", href: "https://www.instagram.com/casa_diesel_cucuta/" },
  { id: 43, nam: "Sinan", img: SinanImg, alt: "Sinan", href: "https://www.instagram.com/sinansaludable/" },
  { id: 44, name: "Madefront", img: MadefrontImg, alt: "Madefront", href: "https://madefront.com/" },
  { id: 45, name: "Dephender Box", img: DephenderBoxImg, alt: "Dephender Box", href: "https://www.instagram.com/dephendercolombia/" },
  { id: 46, name: "Lavandería Stone Color", img: LavStoneColorImg, alt: "Lavandería Stone Color", href: "https://www.facebook.com/lavanderia.stonecolor" },
  { id: 47, name: "Distrito 4", img: Distrito4Img, alt: "Distrito 4", href: "https://www.facebook.com/creacionesdailyn/" },
  { id: 48, name: "Jhon Pastel", img: JhonPastelImg, alt: "Jhon Pastel", href: "https://www.facebook.com/jhonpastel" },
  { id: 48, name: "Arrocera Nelly", img: ArroceraNellyImg, alt: "Arrocera Nelly", href: "#" },
  { id: 50, name: "D'Chic", img: DChicImg, alt: "D'Chic", href: "#" },
  { id: 51, name: "Ceta", img: CetaImg, alt: "Ceta", href: "#" },
  { id: 52, name: "Clínica Oftalmológica Peñaranda", img: ClincaPenarandaImg, alt: "Clínica Oftalmológica Peñaranda", href: "#" },
  { id: 53, name: "Club de cazadores", img: ClubDeCazadoresImg, alt: "Club de cazadores", href: "#" },
  { id: 54, name: "Celeus", img: CeleusImg, alt: "Celeus", href: "#" },
  { id: 55, name: "Del Corte Angarita", img: DelCorteAngaritaImg, alt: "Del Corte Angarita", href: "#" },
  { id: 56, name: "Del Ben", img: DelBenImg, alt: "Del Ben", href: "#" },
  { id: 57, name: "Distrifar Center", img: DistrifarCenterImg, alt: "Distrifar Center", href: "#" },
  { id: 58, name: "ES Sierra Nevada", img: ESSierraNevadaImg, alt: "ES Sierra Nevada", href: "#" },
  { id: 59, name: "ES Ventura", img: ESVenturaImg, alt: "ES Ventura", href: "#" },
  { id: 60, name: "Fusaro", img: FusaroImg, alt: "Fusaro", href: "#" },
  { id: 61, name: "EDS", img: EDSImg, alt: "EDS", href: "#" },
  { id: 62, name: "Galautos", img: GalautosImg, alt: "Galautos", href: "#" },
  { id: 63, name: "Generin", img: GenerinImg, alt: "Generin", href: "#" },
  { id: 64, name: "Grupo Berza", img: GrupoBerzaImg, alt: "Grupo Berza", href: "#" },
  { id: 65, name: "Multiservicios H&F", img: MultiserviciosHFImg, alt: "Multiservicios H & F", href: "#" },
  { id: 66, name: "Progresando en Salud", img: ProgresandoEnSaludImg, alt: "Progresando en Salud", href: "#" },
  { id: 67, name: "Vegaltex", img: VegaltexImg, alt: "Vegaltex", href: "#" },
  { id: 68, name: "Ingenin", img: IngeninImg, alt: "Ingenin", href: "#" },
  { id: 69, name: "SE 21", img: SE21Img, alt: "SE 21", href: "#" },
  { id: 70, name: "Medcare", img: MedcareImg, alt: "Medcare", href: "#" },
  { id: 71, name: "Campo Express", img: CampoExpressImg, alt: "Campo Express", href: "#" },
  { id: 72, name: "La Gran Cosecha", img: LaGranConsechaImg, alt: "La Gran Cosecha", href: "#" },
  { id: 73, name: "Lavandería Victoria", img: LavVictoriaImg, alt: "Lavandería Victoria", href: "#" },
  { id: 74, name: "La Gran Esquina", img: LaGranEsquinaImg, alt: "La Gran Esquina", href: "#" },
  { id: 75, name: "Lavandería New Wash", img: LavNewWashImg, alt: "Lavandería New Wash", href: "#" },
  { id: 76, name: "Maquinados Industriales JH", img: MaquinadosIndustrialesJHImg, alt: "Maquinados Industriales JH", href: "#" },
  { id: 77, name: "MB", img: MBImg, alt: "MB", href: "#" },
  { id: 78, name: "Mincoal", img: MincoalImg, alt: "Mincoal", href: "#" },
  { id: 79, name: "Epicco", img: EpiccoImg, alt: "Epicco", href: "#" },
  { id: 80, name: "Oficanon", img: OficanonImg, alt: "Oficanon", href: "#" },
  { id: 81, name: "Prodotto", img: ProdottoImg, alt: "Prodotto", href: "#" },
  { id: 82, name: "PTD", img: PTDImg, alt: "PTD", href: "#" },
  { id: 83, name: "De la Casa", img: DeLaCasaImg, alt: "De la Casa", href: "#" },
  { id: 84, name: "Saray Construcciones", img: SarayConstruccionesImg, alt: "Saray Construcciones", href: "#" },
  { id: 85, name: "Dori", img: DoriImg, alt: "Dori", href: "#" },
  { id: 86, name: "TNS", img: TNSImg, alt: "TNS", href: "#" },
  { id: 87, name: "Transinver CTC", img: TransinverCTCImg, alt: "Transinver CTC", href: "#" },
  { id: 88, name: "Traperos El Mejor", img: TraperosElMejorImg, alt: "Traperos El Mejor", href: "#" },
  { id: 89, name: "TAC", img: TACImg, alt: "TAC", href: "#" },
  { id: 90, name: "Universo Agrícola", img: UniversoAgricolaImg, alt: "Universo Agrícola", href: "#" },
  { id: 91, name: "YLo Shoes", img: YLoShoesImg, alt: "YLo Shoes", href: "#" },
  { id: 92, name: "McCormick", img: MCCormickImg, alt: "McCormick", href: "#" },
  { id: 93, name: "ES Zona Refrescante", img: ESZonaRefrescanteImg, alt: "ES Zona Refrescante", href: "#" },
  { id: 94, name: "Veinte", img: VeinteImg, alt: "Veinte", href: "#" },
  { id: 95, name: "Proinelectro", img: ProinelectroImg, alt: "Proinelectro", href: "#" },
  { id: 96, name: "Vaox", img: VaoxImg, alt: "Vaox", href: "#" },
  { id: 97, name: "Coopar", img: CooparImg, alt: "Coopar", href: "#" },
  { id: 98, name: "Metro Pizza", img: MetroPizzaImg, alt: "Metro Pizza", href: "#" },
  { id: 99, name: "CMI", img: CMIImg, alt: "CMI", href: "#" },
  { id: 100, name: "CDA", img: CDAImg, alt: "CDA", href: "#" },
  { id: 101, name: "El Punto Tornillo", img: ElPuntoTornilloImg, alt: "El Punto Tornillo", href: "#" },
  { id: 102, name: "Megawash", img: MegawashImg, alt: "Megawash", href: "#" },
  { id: 103, name: "Visit Cúcuta", img: VisitCucutaImg, alt: "Visit Cúcuta", href: "#" },
  { id: 104, name: "Gymwear", img: GymwearImg, alt: " Gymwear", href: "#" },
  { id: 105, name: "El Puntazo", img: ElPuntazoImg, alt: "El Puntazo", href: "#" },
  { id: 106, name: "Studio 13", img: Studio13Img, alt: "Studio 13", href: "#" }
];

export default function AffiliatedCompanies() {
    return (
      <div className="bg-white" id='empresas-afiliadas'>
        <div className='container mx-auto max-w-7xl py-12'>
          <h2 className="text-3xl mb-6 text-green-corpo text-center font-extrabold tracking-tight sm:text-4xl">
            Empresas afiliadas
          </h2>
            <AffiliatedCompaniesSlider>
              {companies.map ((company, index) => (
                <div key={index}>
                  <div className='flex justify-center'>
                    <a href={company.href} target="_blank" className="focus:outline-none" rel="nofollow">
                      <img src={company.img} className='h-[150px]' alt={company.alt} />
                    </a>
                  </div>
                </div>
              ))}
            </AffiliatedCompaniesSlider>
        </div>
      </div>
    )
  }