import SmallSetPagination from "components/pagination/SmallSetPagination"
import BlogCardHorizontal from "./BlogCardHorizontal"

function BlogList({posts,get_blog_list_page,count}){

  return(
    <div className="overflow-hidden bg-white">
      <h1 className="text-3xl font-bold text-center text-green-corpo">Blog</h1>
      <div className="mt-6 mb-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-5">
        {posts && posts.map((post,index) => (
          <BlogCardHorizontal data={post} key={index} index={index}/>
        ))}
      </div>
      <SmallSetPagination list_page={get_blog_list_page} list={posts} count={count} />
    </div>
  )
}
export default BlogList