import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

function CategoriesHeader({ categories }) {
  const location = useLocation();
  const navigate = useNavigate();

  // SEARCH
  const [term, setTerm] = useState("");
  const handleChange = (e) => {
    setTerm(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setTimeout(() => navigate("/s=" + term), 0.2);
    setTerm("");
  };

  return (
    <div className="container mx-auto max-w-6xl">
			<div className="w-full bg-gray-50 py-2 px-4 rounded-xl">
				<div className="grid grid-cols-12">
					<div className="col-span-9">
						<div className="">
							<div className="relative">
								<div className="relative w-full overflow-x-auto">
									<ul role="list" className="mx-4 inline-flex space-x-6 sm:mx-6 ">
										<Link
											to="/blog"
											className={`${
												location.pathname === "/blog"
													? "text-green-corpo bg-white"
													: "text-gray-900 hover:text-green-corpo border border-gray-100 hover:border-gray-200"
											} py-2 px-4 rounded-full text-xs`}
										>
											Todos
										</Link>
										{categories &&
											categories.map((category, index) => (
												<Link
													key={index}
													to={`/category/${category.slug}`}
													className={`${
														location.pathname === `/category/${category.slug}`
															? "text-green-corpo bg-white"
															: "text-gray-900 border border-gray-100 hover:border-gray-200"
													} py-2 px-4 rounded-full text-xs`}
												>
													{category.name}
												</Link>
											))}
									</ul>
								</div>
							</div>
						</div>
					</div>
					<form
						onSubmit={(e) => onSubmit(e)}
						className="relative col-span-3"
					>
						<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
							<i className="bx bx-search-alt text-xl text-gray-400"></i>
						</div>
						<input
							id="search"
							name="search"
							value={term}
							onChange={(e) => handleChange(e)}
							type="search"
							className={`
								py-1 pl-10 pr-3 
								block w-full rounded-full
								border border-gray-200
								focus:border-gray-200 focus:ring-gray-200
								focus:outline-none focus:ring-1
								placeholder-gray-600 focus:placeholder-gray-500
							`}
						/>
					</form>
				</div>
			</div>
		</div>
  );
}
export default CategoriesHeader;
