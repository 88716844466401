export function slideInFromLeft(delay) {
  return {
    offScreen: {
      x: -50,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  };
}

export function slideInFromRight(delay) {
  return {
    offScreen: {
      x: 50,
      opacity: 0,
    },
    onScreen: {
      x: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  };
}

export function slideInFromTop(delay) {
  return {
    offScreen: {
      y: -50,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  }
};

export function slideInFromBottom(delay) {
  return {
    offScreen: {
      y: 50,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  }
};

export function zoomIn(delay) {
  return {
    offScreen: {
      scale: 0.5,
      y: 100,
      x: -100,
      opacity: 0,
    },
    onScreen: {
      scale: 1,
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  }
};

export function zoomOut(delay) {
  return {
    offScreen: {
      y: -50,
      opacity: 0,
    },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: {
        delay: delay,
        duration: 0.4,
      },
    },
  }
};