export const GET_JOB_LIST_SUCCESS = 'GET_JOB_LIST_SUCCESS';
export const GET_JOB_LIST_FAIL = 'GET_JOB_LIST_FAIL';

export const GET_AUTHOR_JOB_LIST_SUCCESS = 'GET_AUTHOR_JOB_LIST_SUCCESS';
export const GET_AUTHOR_JOB_LIST_FAIL = 'GET_AUTHOR_JOB_LIST_FAIL';

export const GET_JOB_LIST_CATEGORIES_SUCCESS = 'GET_JOB_LIST_CATEGORIES_SUCCESS';
export const GET_JOB_LIST_CATEGORIES_FAIL = 'GET_JOB_LIST_CATEGORIES_FAIL';

export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_FAIL = 'GET_JOB_FAIL';

export const GET_SEARCH_JOB_SUCCESS = 'GET_SEARCH_JOB_SUCCESS';
export const GET_SEARCH_JOB_FAIL = 'GET_SEARCH_JOB_FAIL';