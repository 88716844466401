import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { useState, Fragment, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "assets/img/logo-full.png";

const solutions = [
  {
    name: "Inicio",
    description: "",
    href: "/#inicio",
  },
  {
    name: "Objetivo",
    description: "",
    href: "/#objetivo",
  },
  {
    name: "Junta Directiva",
    description: "",
    href: "/junta-directiva",
  },
  {
    name: "Empresas Afiliadas",
    description: "",
    href: "/empresas-afiliadas",
  },
  {
    name: "Blog",
    description: "",
    href: "/blog",
  },
  // {
  //   name: "Bolsa de Empleo",
  //   description: "",
  //   href: "/empleos",
  // },
  // {
  //   name: "Ingresar",
  //   description: "",
  //   href: "https://cie.corpoindustrial.co",
  // },
];

function Navbar() {
  const [loading, setLoading] = useState(true);

  // window.onscroll = function () {
  // 	scrollFunction();
  // };

  // function scrollFunction() {
  // 	if (document.getElementById("navbar")) {
  // 		if (
  // 			document.body.scrollTop > 50 ||
  // 			document.documentElement.scrollTop > 50
  // 		) {
  // 			document.getElementById("navbar").classList.add("shadow-navbar", "backdrop-blur-md", "bg-white/40");
  // 		} else {
  // 			document.getElementById("navbar").classList.remove("shadow-navbar", "backdrop-blur-md", "bg-white/40");
  // 		}
  // 	}
  // }

  const [open, setOpen] = useState(false);

  return (
    <nav
      data-scroll
      data-scroll-id="hey"
      id="navbar"
      className="transition duration-100 ease-in-out z-40 fixed rounded-md w-full shadow-navbar backdrop-blur-md bg-white/60"
    >
      <div className="px-2 xl:px-4">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
          <Link to="/" className="ml-4 mt-2">
            <img src={logo} width={150} height={110} className="" />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <a
              href="/#inicio"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Inicio
            </a>
            <a
              href="/#goal"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Objetivo
            </a>
            <a
              href="/#junta-directiva"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Junta Directiva
            </a>
            <a
              href="/#empresas-afiliadas"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Empresas Afiliadas
            </a>
            <NavLink
              to="/blog"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Blog
            </NavLink>
            {/* <NavLink
              to="/empleos"
              className="inline-flex font-semibold leading-6 text-gray-900 border-b-2 border-transparent hover:border-green-corpo hover:text-green-corpo transition duration-300 ease-in-out mx-3"
            >
              Bolsa de Empleo
            </NavLink>

            <a
              href="https://cie.corpoindustrial.co"
              target="_blank"
              className="inline-flex ml-3 items-center rounded-md border border-transparent bg-green-corpo px-4 py-1.5 text-base font-medium text-white shadow-sm hover:bg-yellow-corpo transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-corpo focus:ring-offset-2"
            >
              Ingresar
            </a> */}
          </div>
        </div>
        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-4 px-2">
          <Link to="/" className="ml-4 mt-3">
            <img src={logo} width={110} height={110} className="" />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`
                                ${open ? "" : "text-opacity-90"}
                                focus:ring-none focus:outline-none`}
                  >
                    {open ? (
                      <i className="bx bx-x text-4xl mt-1"></i>
                    ) : (
                      <i className="bx bx-menu text-4xl mt-1"></i>
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-32 z-10 mt-5 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-green-corpo focus-visible:ring-opacity-50"
                            >
                              <div className="ml-4">
                                <p className="text-sm font-medium text-gray-900">
                                  {item.name}
                                </p>
                                <p className="text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Navbar);

