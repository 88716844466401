import React, { useEffect } from "react";
import { get_categories } from "redux/actions/categories/categories";
import { connect } from "react-redux";
import { get_blog_list } from "redux/actions/blog/blog";
import BlogCardHorizontal from "components/blog/BlogCardHorizontal";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function BlogList({
  get_categories,
  get_blog_list,
  posts,
}) {

  useEffect(() => {
    window.scrollTo(0, 0);
    get_categories();
    get_blog_list();
  }, [get_categories, get_blog_list]);

  return (
    <div className="bg-gray-100 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="container mx-auto max-w-7xl">
        <div className="relative mx-auto lg:mx-12 max-w-lg divide-y-2 divide-gray-200 lg:max-w-full">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-green-corpo sm:text-4xl">Publicaciones recientes</h2>
            <p className="mt-3 text-xl text-gray-500 sm:mt-4">
              Lee las publicaciones más recientes de nuestro blog.
            </p>
          </div>
          <div className="mt-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
            {Array.isArray(posts) && posts.length > 0 ? (
              posts.slice(0, 3).map((post, index) => (
                <BlogCardHorizontal data={post} key={index} index={index} />
            ))) : (
              <div className="">
                <p className="text-2xl text-gray-500">No hay publicaciones</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.categories.categories,
    posts: state.blog.blog_list,
  };
};

export default connect(mapStateToProps, {
  get_categories,
  get_blog_list,
})(BlogList);
