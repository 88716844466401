import BlogList from "components/home/BlogList";
import CTA from "components/home/CTA";
import Businessmen from "components/home/Businessmen";
import Header from "components/home/Header";
import AffiliatedCompanies from "components/home/AffiliatedCompanies";
import Goal from "components/home/Goal";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import SocialButton from "components/buttons/SocialButton";
import Awards from "components/home/Awards";

function Home() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div data-scroll-section className="pt-20 overflow-hidden" id="inicio">
				<SocialButton />
				<Header />
				<Awards />
				<Goal />
				<BlogList />
				<Businessmen />
				<CTA />
				<AffiliatedCompanies />
			</div>
			<Footer />
		</Layout>
	);
}
export default Home;
