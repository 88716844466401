import React, { useState, Fragment, useRef } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import logoAwards from 'assets/img/logo-awards.png'

function AwardsForm() {
  const [formData, setFormData] = useState({
    empresa: '',
    sector: '',
    telefono_empresa: '',
    email_empresa: '',
    sitio_web: '',
    representante_legal: '',
    cargo: '',
    telefono: '',
    email: '',
    categoria: '',
    descripcion_video: '',
    autorizacion_datos: false,
    confirmacion_asistencia: false,
  })

  const [video, setVideo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const videoInputRef = useRef(null)
  // const soporteInputRef = useRef(null)
  // const certificacionInputRef = useRef(null)

  const normalizeEmails = (data) => {
    const normalizedData = { ...data };
    Object.keys(normalizedData).forEach((key) => {
      if (key.toLowerCase().includes('email')) {
        normalizedData[key] = normalizedData[key].toLowerCase();
      }
    });
    return normalizedData;
  };

  const handleResetForm = () => {
    setFormData({
      empresa: '',
      sector: '',
      telefono_empresa: '',
      email_empresa: '',
      sitio_web: '',
      representante_legal: '',
      cargo: '',
      telefono: '',
      email: '',
      categoria: '',
      descripcion_video: '',
      autorizacion_datos: false,
      confirmacion_asistencia: false,
    });
    videoInputRef.current.value = ''

  };

  // Handle changes in text and select inputs
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    const fieldsWithValidation = ['empresa',  'sector', 'telefono_empresa', 'representante_legal', 'telefono', 'cargo'];

    // Expresión regular para solo letras con acentos y números
    const regex = /^[a-zA-ZÀ-ÿ0-9\s]*$/;

    // Si el campo requiere validación y no coincide con la expresión, no actualizar el estado
    if (fieldsWithValidation.includes(name) && !regex.test(value)) {
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleFileChange = (e) => {
    const { name, files } = e.target;
  
    const validateFiles = (files) => {
      if (files.length === 0) return;
  
      if (name === 'video') {
        const video = files[0];
        // Validar tipo de archivo (solo MP4)
        if (video.type !== 'video/mp4') {
          setErrorMessage('Solo se permite un archivo en formato MP4');
          setModalOpen(true);
          videoInputRef.current.value = '';
          return false;
        }
        // Validar tamaño de archivo (máximo 50MB)
        if (video.size > 50 * 1024 * 1024) {
          setErrorMessage('El archivo de video no debe exceder los 50MB');
          setModalOpen(true);
          videoInputRef.current.value = '';
          return false;
        }
      }
      return true; // Si todo es válido
    };
  
    // Validar archivos antes de actualizar el estado
    const isValid = validateFiles(files);
    if (!isValid) return;  // Si no es válido, no actualizamos el estado
  
    // Si el archivo es válido, actualizar el estado
    setVideo(files[0]);
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    const normalizedFormData = normalizeEmails(formData);
    const data = new FormData()
    Object.keys(normalizedFormData).forEach((key) => {
      data.append(key, normalizedFormData[key])
    })
    if (video) data.append('video', video);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reconocimiento/`, data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        dispatch({ type: 'FORM_SUBMIT_SUCCESS', payload: response.data })
        setLoading(false)
        setIsModalOpen(true)  // Abre el modal cuando el envío es exitoso
        handleResetForm()
        
      } catch (error) {
        console.error(error)
        setError('Error al enviar el formulario. Inténtalo de nuevo.')
        setLoading(false)
      }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='bg-white backdrop-blur-md shadow-2xl rounded-lg p-4 lg:p-8'>
          <div className='mb-8 flex justify-center'>
            {/* <h1 className='text-2xl font-bold text-center'>Premios Corpoindustrial</h1> */}
            <div className='flex justify-center w-96'>
              <img src={logoAwards} alt="Logo Premios Corpoindustrial" />
            </div>
          </div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Datos generales de la empresa</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Nombre de la Empresa 
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="text"
                  name="empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  placeholder="Nombre de la empresa"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              {/* <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    NIT
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="text"
                  name="nit"
                  value={formData.nit}
                  onChange={handleChange}
                  placeholder="NIT"
                  className="input input-bordered w-full"
                  required
                />
              </label> */}
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Sector al que pertenece la Empresa
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <select 
                  name="sector"
                  value={formData.sector}
                  onChange={handleChange}
                  className="select select-bordered"
                  required

                >
                <option value="">Seleccione el sector</option>
                <option value="INDUSTRIALES">INDUSTRIALES</option>
                <option value="TEXTIL">TEXTIL</option>
                <option value="TRANSPORTE">TRANSPORTE</option>
                <option value="CONSTRUCCION">CONSTRUCCION</option>
                <option value="ALIMENTOS">ALIMENTOS</option>
                <option value="AUTOPARTES">AUTOPARTES</option>
                <option value="COMERCIO">COMERCIO</option>
                <option value="SALUD">SALUD</option>
                <option value="EDUCACIÓN">EDUCACIÓN</option>
                <option value="MINERIA">MINERIA</option>
                <option value="CALZADO">CALZADO</option>
                </select>
              </label>
              {/* <label className="form-control col-span-1 lg:col-span-2">
                <div className="label">
                  <span className="label-text">
                    Dirección
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <textarea
                  name="direccion"
                  value={formData.direccion}
                  onChange={handleChange}
                  className="textarea textarea-bordered h-16 resize-none"
                  placeholder="Dirección de la Empresa"
                  required>
                </textarea>
              </label> */}
              {/* <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Ciudad
                    <span className='text-red-600 font-bold'> *</span>  
                  </span>
                </div>
                <input
                  type="text"
                  name="ciudad"
                  value={formData.ciudad}
                  onChange={handleChange}
                  placeholder="Ciudad"
                  className="input input-bordered w-full"
                  required
                />
              </label> */}
              {/* <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Departamento
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="text"
                  name="departamento"
                  value={formData.departamento}
                  onChange={handleChange}
                  placeholder="Departamento"
                  className="input input-bordered w-full"
                  required
                />
              </label> */}
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Teléfono de la empresa
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="tel"
                  name="telefono_empresa"
                  value={formData.telefono_empresa}
                  onChange={handleChange}
                  placeholder="Teléfono"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Correo de la empresa
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="email"
                  name="email_empresa"
                  value={formData.email_empresa}
                  onChange={handleChange}
                  placeholder="Correo electrónico"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Enlace a sitio web o red social principal (opcional)
                  </span>
                </div>
                <input
                  type="url"
                  name="sitio_web"
                  value={formData.sitio_web}
                  onChange={handleChange}
                  placeholder="https://www.ejemplo.com"
                  className="input input-bordered w-full"
                />
              </label>
            </div>
          </div>
          <div className="divider"></div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Datos del representante legal</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Nombre completo
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="text"
                  name="representante_legal"
                  value={formData.representante_legal}
                  onChange={handleChange}
                  placeholder="Nombre completo"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Cargo
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="text"
                  name="cargo"
                  value={formData.cargo}
                  onChange={handleChange}
                  placeholder="Cargo"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Teléfono de contacto
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="tel"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  placeholder="Teléfono de contacto"
                  className="input input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Correo electrónico
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Correo electrónico"
                  className="input input-bordered w-full"
                  required
                />
              </label>
            </div>
          </div>
          <div className="divider"></div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Información sobre la postulación</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Categoría a la que se postula
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <select
                  name="categoria"
                  value={formData.categoria}
                  onChange={handleChange}
                  className="select select-bordered"
                  required
                >
                  <option disabled defaultValue value="">Seleccione</option>
                  <option value="Premio a la Innovación y Transformación Digital">Premio a la Innovación y Transformación Digital</option>
                  <option value="Premio al Compromiso Ambiental">Premio al Compromiso Ambiental</option>
                  <option value="Premio a la Responsabilidad Social Empresarial">Premio a la Responsabilidad Social Empresarial</option>
                  <option value="Premio al Crecimiento Empresarial y Generación de Empleo">Premio al Crecimiento Empresarial y Generación de Empleo</option>
                </select>
              </label>
            </div>
          </div>
          <div className="divider"></div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Video de postulación</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Carga el video
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </div>
                <p className='text-xs mb-2 italic text-gray-500'>Agrega un video en formato mp4 de máximo 2 minutos y con un peso menor a 50mb.</p>
                <input
                  type="file"
                  name="video"
                  ref={videoInputRef}
                  onChange={handleFileChange}
                  accept='video/mp4'
                  className="file-input file-input-bordered w-full"
                  required
                />
              </label>
              <label className="form-control col-span-1 lg:col-span-2">
                <div className="label">
                  <span className="label-text">
                    Descripción breve del video (opcional)
                  </span>
                </div>
                <textarea
                  name="descripcion_video"
                  value={formData.descripcion_video}
                  onChange={handleChange}
                  className="textarea textarea-bordered h-16 resize-none"
                  placeholder="Descripción del video"
                >  
                </textarea>
              </label>
            </div>
          </div>
          {/* <div className="divider"></div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Evidencia o soporte (Opcional)</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Documentación de respaldo</span>
                </div>
                <p className='text-xs mb-2 italic text-gray-500'>Solo admite archivos PDF de máximo 2mb</p>
                <input
                  type="file"
                  name="soporte"
                  ref={soporteInputRef}
                  onChange={handleFileChange}
                  accept='application/pdf'
                  className="file-input file-input-bordered w-full"
                />
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Certificación o requerimientos previos</span>
                </div>
                <p className='text-xs mb-2 italic text-gray-500'>Solo admite archivos PDF de máximo 2mb</p>
                <input
                  type="file"
                  name="certificacion"
                  ref={certificacionInputRef}
                  onChange={handleFileChange}
                  accept='application/pdf'
                  className="file-input file-input-bordered w-full"
                />
              </label>
            </div>
          </div> */}
          <div className="divider"></div>
          <div className='mb-8'>
            <h2 className='mb-4 font-bold text-xl'>Autorizaciones</h2>
            <div className=''>
              <div className="form-control">
                <label className="label cursor-pointer justify-start">
                  <input
                    type="checkbox"
                    name="autorizacion_datos"
                    checked={formData.autorizacion_datos}
                    onChange={handleChange}
                    className="checkbox"
                  />
                  <span className="label-text ml-1.5">
                    Autorizo el uso de los datos suministrados
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </label>
              </div>
              <div className="form-control">
                <label className="label cursor-pointer justify-start">
                  <input
                    type="checkbox"
                    name="confirmacion_asistencia"
                    checked={formData.confirmacion_asistencia}
                    onChange={handleChange}
                    className="checkbox"
                  />
                  <span className="label-text ml-1.5">
                    Confirmo mi participación en el Evento
                    <span className='text-red-600 font-bold'> *</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className='flex justify-center lg:justify-end'>
            <button
              type="submit"
              disabled={loading || !formData.autorizacion_datos}
              className={`${!formData.autorizacion_datos ? 'bg-gray-400' : 'bg-green-corpo hover:bg-green-800 hover:scale-110'} text-white px-6 py-2 rounded-md transition-all duration-150 ease-in-out`}
            >
              {loading ? 'Enviando...' : 'Registrar mi Empresa'}
            </button>
          </div>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
      </form>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    ¡Datos registrados correctamente!
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Muchas gracias por tu postulación.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-green-corpo bg-white px-4 py-2 text-sm font-medium hover:border-green-800 text-green-corpo hover:text-green-800"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {errorMessage}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Por favor, verifica que el archivo cumpla con los requisitos.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-green-corpo bg-white px-4 py-2 text-sm font-medium hover:border-green-800 text-green-corpo hover:text-green-800"
                      onClick={() => setModalOpen(false)}
                    >
                      Cerrar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  )
}

export default AwardsForm